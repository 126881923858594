import { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import logo from "../media/logo.png";
import { Api } from "../components/Knihovna";

//--------------------------------------------

// udelat jinak lidi co dosahli pocet bodu pro odznacek

//----------------------------------------------

const TobanJePica = [
  {
    index: 1,
    id: 1545468778546,
    name: "Swag",
    nick: "@drip",
    skore: 69,
    cas: "15:23",
  },
  {
    index: 2,
    id: 4561231548840,
    name: "Pepa",
    nick: "@pepikczlp",
    skore: 420,
    cas: "17:58",
  },
  {
    index: 3,
    id: 5879852612654,
    name: "Horny",
    nick: "@adamekcz",
    skore: 1337,
    cas: "18:50",
  },
  {
    index: 4,
    id: 9595360148426,
    name: "Květoslav",
    nick: "@xxpussyslayerxx",
    skore: 69420,
    cas: "9:26",
  },
];

export default function Home() {
  const [sortedUsers, setSortedUsers] = useState(TobanJePica);
  const [ledr, setLedr] = useState(TobanJePica);
  
  

  useEffect(() => {
    const intervalId = setInterval(() => {
      Api.getLeadboard().then((res) => {
        console.log(res)
        setLedr(res);
        ledr.sort((a, b) => b.score - a.score);
        setSortedUsers(ledr);
      })

    
   //window.location.reload();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [ledr]);

  return (
    <>
      <Disclosure as="nav" className="bg-gray-800 bg-peknaCerna text-bila">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img className="h-10 w-13" src={logo} alt="CeHaCe" />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
      <header className="bg-zluta shadow">
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-left tracking-tight text-gray-900">
            Leaderboard
          </h1>
        </div>
      </header>
      <div className="flex mt-5 w-11/12 mx-auto">
        <div className="row mx-auto w-full">
          <ul className="divide-y col-8 divide-gray-100">
            {sortedUsers.map((pica, i) => (
              <li
                key={i}
                className="flex flex-row max-w-screen overflow-x-hidden border-none justify-between my-5 shadow-xl py-5 transition ease-out delay-500 rounded-md first:bg-zluta  md:py-5 first:text-peknaCerna [&:nth-child(3)]:bg-bronzova [&:nth-child(2)]:bg-stribrna bg-sedaaa "
              >
                <div className="text-left w-fit min-w-0">
                  <p className="inline-block md:px-5 text-xl md:text-4xl mx-5 md:mx-10 font-semibold leading-10 text-gray-900">
                    {i + 1}
                  </p>
                  <p className="inline-block md:px-5 text-xl md:text-4xl my-2 font-semibold leading-6 text-gray-900">
                    {pica.displayName}
                  </p>
                </div>
                <div className="shrink-0 sm:items-end">
                  <p className="md:px-5 text-xl md:text-4xl pt-2 text-right mr-5 leading-6 text-gray-900">
                    {pica.score}
                  </p>
                  {pica.lastSeen ? (
                    <p className="mt-1 text-xs leading-5 text-gray-500">
                      Last seen{" "}
                      <time dateTime={pica.lastSeenDateTime}>
                        {pica.lastSeen}
                      </time>
                    </p>
                  ) : (
                    <div></div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
